import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
import ImportProjectModal from "../../components/dashboard/projects/ImportProjectModal";
import UseProjectTemplate from "../../components/dashboard/projects/UseProjectTemplate";
import ViewAllProjectListTable from "../../components/dashboard/projects/ViewAllProjectListTable";
import PermissionGuard from "../../components/PermissionGuard";
import useProject from "../../hooks/useProject";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import PlusIcon from "../../icons/Plus";
const ViewAllProjects = () => {
  const { settings } = useSettings();

  const { allProjects, getAllProject, totalProjectCount } = useProject();
  const [showImportProjectModal, setShowImportProjectModal] = useState(false);
  const [showUseProjectTemplate, setShowUseProjectTemplate] = useState(false);

  const handleAllProject = (pageNum, projectType, projectName) => {
    getAllProject(pageNum, projectType, projectName);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: View All Projects |</title>
      </Helmet>
      {showImportProjectModal && (
        <ImportProjectModal
          showImportProjectModal={showImportProjectModal}
          setShowImportProjectModal={setShowImportProjectModal}
          handleAllProject={handleAllProject}
        />
      )}
      {showUseProjectTemplate && (
        <UseProjectTemplate
          showUseProjectTemplate={showUseProjectTemplate}
          setShowUseProjectTemplate={setShowUseProjectTemplate}
          handleAllProject={handleAllProject}
        />
      )}
      {/* <VideoFloatButton tutorialName="project" /> */}

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Projects
                </Typography>
                <Typography color="textSecondary" variant="breadCrumbText">
                  View All
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                Projects
              </Typography>
              <Box
                sx={{
                  mt: 0.5,
                }}
              >
                <Typography
                  color="textSecondary"
                  component="span"
                  variant="dashboardInfo"
                >
                  Total Projects:
                </Typography>
                <Typography
                  component="span"
                  variant="dashboardInfo"
                  sx={{
                    color: "#00000E",
                    pl: 1,
                  }}
                >
                  {totalProjectCount}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                {/* <Button
                  color="primary"
                  sx={{ m: 1 }}
                  variant="outlined"
                  onClick={() => setShowImportProjectModal(true)}
                >
                  IMPORT PROJECT
                </Button> */}
                <PermissionGuard
                  permissionCheck="ADMIN_PROJECT_TEMPLATE"
                  returnEmpty={true}
                >
                  <Button
                    color="primary"
                    sx={{ m: 1 }}
                    variant="outlined"
                    onClick={() => setShowUseProjectTemplate(true)}
                  >
                    Apply Template
                  </Button>
                </PermissionGuard>
                <PermissionGuard
                  permissionCheck="PROJECT_CREATE"
                  returnEmpty={true}
                >
                  <Button
                    color="primary"
                    sx={{ m: 1 }}
                    startIcon={<PlusIcon fontSize="small" />}
                    variant="contained"
                    component={RouterLink}
                    to="/dashboard/projects/add-new-project"
                  >
                    ADD NEW PROJECT
                  </Button>
                </PermissionGuard>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <ViewAllProjectListTable
              allProject={allProjects}
              handleAllProject={handleAllProject}
              getAllProject={getAllProject}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ViewAllProjects;
