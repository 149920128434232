import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getMilestoneReport } from "../../../api/portfolio/Portfolio";

const MilestoneReport = () => {
  const [reportData, setReportData] = useState([]);
  useEffect(() => {
    getMilestones();
  }, []);

  const getMilestones = async () => {
    const res = await getMilestoneReport(230);
    console.log(res);
    setReportData(res?.data?.data?.values);
  };
  return (
    <>
      {reportData?.length ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Project</TableCell>
              <TableCell>Stage</TableCell>
              <TableCell>Milestone</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Budget</TableCell>
              <TableCell>Actual</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{reportData[0].project.name}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            {reportData.map((stage, index) => (
              <>
                <TableRow key={index}>
                  <TableCell></TableCell>
                  <TableCell>{stage.stage}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {stage.milestones.map((milestone, index) => (
                  <TableRow key={index}>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>{milestone.name}</TableCell>
                    <TableCell>{milestone.start_date}</TableCell>
                    <TableCell>{milestone.end_date}</TableCell>
                    <TableCell>{milestone.budget}</TableCell>
                    <TableCell>{milestone.actual}</TableCell>
                  </TableRow>
                ))}
              </>
            ))}

            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>

            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>Year 1</TableCell>
              <TableCell>Year 2</TableCell>
              <TableCell>Year 3</TableCell>
              <TableCell>Year 4</TableCell>
              <TableCell>Year 5</TableCell>
            </TableRow>
            <TableRow
              sx={{
                "&:nth-of-type(odd)": {
                  backgroundColor: "#f8c787d6",
                },
              }}
            >
              <TableCell>Financial</TableCell>
              <TableCell>Required Investment</TableCell>
              <TableCell>$2,000,000</TableCell>
              <TableCell>$1,000,000</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow
              sx={{
                td: {
                  backgroundColor: "#5ad274d6",
                },
              }}
            >
              <TableCell></TableCell>
              <TableCell>Projected Revenue</TableCell>
              <TableCell></TableCell>
              <TableCell>$1,200,000</TableCell>
              <TableCell>$12,000,000</TableCell>
              <TableCell>$58,500,000</TableCell>
              <TableCell>$151,500,000</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        ""
      )}
    </>
  );
};

export default MilestoneReport;

const Temp = () => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Project</TableCell>
        <TableCell>Milestone</TableCell>
        <TableCell>PROD1</TableCell>
        <TableCell>PROD2</TableCell>
        <TableCell>PROD3</TableCell>
        <TableCell>FORECAST EXPENSE</TableCell>
        <TableCell>SPEND TO DATE </TableCell>
        <TableCell>VARIANCE</TableCell>
        {/* <TableCell>Project Completion</TableCell> */}
        <TableCell>notes</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell>Operations</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        {/* <TableCell>23</TableCell> */}
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>Set up offices and labs (SETUP & ADMIN)</TableCell>
        <TableCell>2</TableCell>
        <TableCell>2</TableCell>
        <TableCell>1</TableCell>
        <TableCell>5</TableCell>
        <TableCell>3</TableCell>
        <TableCell>2</TableCell>
        {/* <TableCell></TableCell> */}
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>Hire personnel (15) (HIRE)</TableCell>
        <TableCell>15</TableCell>
        <TableCell>15</TableCell>
        <TableCell>15</TableCell>
        <TableCell>45</TableCell>
        <TableCell>20</TableCell>
        <TableCell>25</TableCell>
        {/* <TableCell></TableCell> */}
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell>IP</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        {/* <TableCell></TableCell> */}
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>IP related activities </TableCell>
        <TableCell>5</TableCell>
        <TableCell>2</TableCell>
        <TableCell>2</TableCell>
        <TableCell>9</TableCell>
        <TableCell>6</TableCell>
        <TableCell>3</TableCell>
        {/* <TableCell></TableCell> */}
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Development</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>Contract with Vendor 1 </TableCell>
        <TableCell>9</TableCell>
        <TableCell>3</TableCell>
        <TableCell>4</TableCell>
        <TableCell>16</TableCell>
        <TableCell>8</TableCell>
        <TableCell>8</TableCell>
        {/* <TableCell></TableCell> */}
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>Complete QA with software provider </TableCell>
        <TableCell>19</TableCell>
        <TableCell>3</TableCell>
        <TableCell>2</TableCell>
        <TableCell>24</TableCell>
        <TableCell>18</TableCell>
        <TableCell>6</TableCell>
        {/* <TableCell></TableCell> */}
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>Hire and oversee software development</TableCell>
        <TableCell>10</TableCell>
        <TableCell>2</TableCell>
        <TableCell>4</TableCell>
        <TableCell>16</TableCell>
        <TableCell>8</TableCell>
        <TableCell>8</TableCell>
        {/* <TableCell></TableCell> */}
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>QA wth vendor 5</TableCell>
        <TableCell>5</TableCell>
        <TableCell>1</TableCell>
        <TableCell>1</TableCell>
        <TableCell>7</TableCell>
        <TableCell>4</TableCell>
        <TableCell>3</TableCell>
        {/* <TableCell></TableCell> */}
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>h. assemble system by vendors 6-7 and test</TableCell>
        <TableCell>4</TableCell>
        <TableCell>2</TableCell>
        <TableCell>2</TableCell>
        <TableCell>8</TableCell>
        <TableCell>3</TableCell>
        <TableCell>5</TableCell>
        {/* <TableCell></TableCell> */}
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Trials and Approvals</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        {/* <TableCell></TableCell> */}
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>Conduct Trial run and get sign-off approvals</TableCell>
        <TableCell>5.5</TableCell>
        <TableCell>2.5</TableCell>
        <TableCell>1.5</TableCell>
        <TableCell>9.5</TableCell>
        <TableCell>4.5</TableCell>
        <TableCell>5</TableCell>
        {/* <TableCell></TableCell> */}
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>Conduct testing/evaluation programs</TableCell>
        <TableCell>3</TableCell>
        <TableCell>2</TableCell>
        <TableCell>2</TableCell>
        <TableCell>7</TableCell>
        <TableCell>3</TableCell>
        <TableCell>4</TableCell>
        {/* <TableCell></TableCell> */}
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Marketing and Sales</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        {/* <TableCell></TableCell> */}
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>Cotinuous Improvement </TableCell>
        <TableCell>8</TableCell>
        <TableCell>4</TableCell>
        <TableCell>4</TableCell>
        <TableCell>16 </TableCell>
        <TableCell>9</TableCell>
        <TableCell>3</TableCell>
        {/* <TableCell></TableCell> */}
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Future</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        {/* <TableCell></TableCell> */}
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>8</TableCell>
        <TableCell>4</TableCell>
        <TableCell>4</TableCell>
        <TableCell>3</TableCell>
        <TableCell></TableCell>
        <TableCell>8</TableCell>
        <TableCell>8</TableCell>
        {/* <TableCell></TableCell> */}
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Total</TableCell>
        <TableCell>Projected Revenues</TableCell>
        <TableCell>200</TableCell>
        <TableCell>400</TableCell>
        <TableCell>500</TableCell>
        <TableCell>1000</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        {/* <TableCell></TableCell> */}
        <TableCell></TableCell>
      </TableRow>
    </TableBody>
  </Table>
);
