import { Typography } from "@material-ui/core";
import { useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { useFetch } from "../../hooks";
import { handleApiRequest } from "../../hooks/callApi";
import TaskCard from "./TaskCard";

export default function SprintBoard({ refreshCount, isColumn, sprintId }) {
  const { projectId } = useParams();
  const { data: projectMembersRes } = useFetch(
    `/api/project/members/${projectId}`
  );

  const { data: tasks, refetch: refetchSprintTasks } = useFetch(
    `/api/sprint-tasks/${sprintId}`
  );

  useEffect(() => {
    if (refreshCount) {
      console.log("refetching", refreshCount);
      refetchSprintTasks();
    }
  }, [refreshCount]);

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const updatedTasks = [...tasks];
    const movedTask = updatedTasks.find(
      (task) => task.id === parseInt(draggableId)
    );

    if (movedTask) {
      console.log(movedTask);
      const res = await handleApiRequest(
        `/api/sprint-tasks/${movedTask.id}`,
        "put",
        {
          status: destination.droppableId,
        }
      );
      console.log(res);
      await refetchSprintTasks();
    }
  };

  return (
    <section
      className={`sprint-board ${
        isColumn ? "sprint-board-column" : "sprint-board-row"
      }`}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="backlog">
          {(provided) => (
            <div
              className="container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <section className="tasks backlog">
                <Typography
                  sx={{ mb: 2 }}
                  variant="subtitle1"
                  fontWeight={"bold"}
                >
                  Backlog
                </Typography>
                <ul className="slots">
                  {tasks
                    ?.filter((task) => task.status === "backlog")
                    ?.map((item, index) => (
                      <Draggable
                        key={item.id}
                        index={index}
                        draggableId={String(item.id)}
                      >
                        {(provided) => (
                          <li
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className="slot"
                          >
                            <TaskCard
                              index={index}
                              item={item}
                              projectMembers={
                                projectMembersRes?.data?.projectMembers?.data ||
                                []
                              }
                              refetchSprintTasks={refetchSprintTasks}
                            />
                          </li>
                        )}
                      </Draggable>
                    ))}
                </ul>
              </section>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Droppable droppableId="to-dos">
          {(provided) => (
            <div
              className="container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <section className="tasks to-dos">
                <Typography
                  sx={{ mb: 2 }}
                  variant="subtitle1"
                  fontWeight={"bold"}
                >
                  To Dos
                </Typography>
                <ul className="slots">
                  {tasks
                    ?.filter((task) => task.status === "to-dos")
                    ?.map((item, index) => (
                      <Draggable
                        key={item.id}
                        index={index}
                        draggableId={String(item.id)}
                      >
                        {(provided) => (
                          <li
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className="slot"
                          >
                            <TaskCard
                              index={index}
                              item={item}
                              projectMembers={
                                projectMembersRes?.data?.projectMembers?.data ||
                                []
                              }
                              refetchSprintTasks={refetchSprintTasks}
                            />
                          </li>
                        )}
                      </Draggable>
                    ))}
                </ul>
              </section>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Droppable droppableId="in-progress">
          {(provided) => (
            <div
              className="container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <section className="tasks in-progress">
                <Typography
                  sx={{ mb: 2 }}
                  variant="subtitle1"
                  fontWeight={"bold"}
                >
                  In Progress
                </Typography>
                <ul className="slots">
                  {tasks
                    ?.filter((task) => task.status === "in-progress")
                    ?.map((item, index) => (
                      <Draggable
                        key={item.id}
                        index={index}
                        draggableId={String(item.id)}
                      >
                        {(provided) => (
                          <li
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className="slot"
                          >
                            <TaskCard
                              index={index}
                              item={item}
                              projectMembers={
                                projectMembersRes?.data?.projectMembers?.data ||
                                []
                              }
                              refetchSprintTasks={refetchSprintTasks}
                            />
                          </li>
                        )}
                      </Draggable>
                    ))}
                </ul>
              </section>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Droppable droppableId="q/a">
          {(provided) => (
            <div
              className="container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <section className="tasks q-a">
                <Typography
                  sx={{ mb: 2 }}
                  variant="subtitle1"
                  fontWeight={"bold"}
                >
                  Q/A
                </Typography>
                <ul className="slots">
                  {tasks
                    ?.filter((task) => task.status === "q/a")
                    ?.map((item, index) => (
                      <Draggable
                        key={item.id}
                        index={index}
                        draggableId={String(item.id)}
                      >
                        {(provided) => (
                          <li
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className="slot"
                          >
                            <TaskCard
                              index={index}
                              item={item}
                              projectMembers={
                                projectMembersRes?.data?.projectMembers?.data ||
                                []
                              }
                              refetchSprintTasks={refetchSprintTasks}
                            />
                          </li>
                        )}
                      </Draggable>
                    ))}
                </ul>
              </section>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Droppable droppableId="done">
          {(provided) => (
            <div
              className="container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <section className="tasks done">
                <Typography
                  sx={{ mb: 2 }}
                  variant="subtitle1"
                  fontWeight={"bold"}
                >
                  Done
                </Typography>
                <ul className="slots">
                  {tasks
                    ?.filter((task) => task.status === "done")
                    ?.map((item, index) => (
                      <Draggable
                        key={item.id}
                        index={index}
                        draggableId={String(item.id)}
                      >
                        {(provided) => (
                          <li
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className="slot"
                          >
                            <TaskCard
                              index={index}
                              item={item}
                              projectMembers={
                                projectMembersRes?.data?.projectMembers?.data ||
                                []
                              }
                              refetchSprintTasks={refetchSprintTasks}
                            />
                          </li>
                        )}
                      </Draggable>
                    ))}
                </ul>
              </section>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </section>
  );
}
