import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";

import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import PlusIcon from "../../icons/Plus";
import RoomIntakeFormListTable from "../../components/dashboard/room-intake-form/RoomIntakeFormListTable";
import { useState } from "react";
import moment from "moment";
import CreateNewRoom from "../../components/dashboard/room-intake-form/CreateNewRoom";


const RoomIntakeForm = () => {
  const { settings } = useSettings();
  const [showModal, setShowModal] = useState(false);
  const [allRooms, setAllRooms] = useState([
    {
        id: 1,
        name: "Event Planning",
        status: "Active",
        contact_person: "John Doe",
        email: "johndoe@gmail.com",
        created_on: "2021-05-01",
        totalRoom: 100,
        occupiedRooms: 85,
    },
    {
        id: 2,
        name: "Birthday Party",
        status: "Active",
        contact_person: "jack",
        email: "jack@email.com",
        created_on: "2021-05-01",
        totalRoom: 78,
        occupiedRooms: 65,
    },
    ])

    const createNewRoom = (roomDetails) => {
        setAllRooms([...allRooms, {id: allRooms.length, created_on: moment(Date.now()).format('MM-DD-YYYY') , ...roomDetails}])
    }

    const increaseOccupiedRooms = (id, num) => {
        const newRooms = allRooms.map(room => {
            if(room.id === id) {
                return {...room, occupiedRooms: room.occupiedRooms + num}
            }
            return room
        })
        setAllRooms(newRooms)
    }

  return (
    <>
      <Helmet>
        <title>Dashboard: View All Portfolios</title>
      </Helmet>
      {/* <VideoFloatButton tutorialName="portfolio" /> */}
        <CreateNewRoom showModal={showModal} setShowModal={setShowModal} createNewRoom={createNewRoom} />
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Intake Form
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                Intake Form
              </Typography>
              <Box
                sx={{
                  mt: 0.5,
                }}
              >
                <Typography
                  color="textPrimary"
                  component="span"
                  variant="dashboardInfo"
                >
                  Total Rooms
                </Typography>
                <Typography
                  component="span"
                  variant="dashboardInfo"
                  sx={{
                    color: "#00000E",
                    pl: 1,
                  }}
                >
                  {allRooms.length}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
                <Box sx={{ m: -1 }}>
                  <Button
                    color="primary"
                    startIcon={<PlusIcon fontSize="small" />}
                    sx={{ m: 1 }}
                    variant="contained"
                    onClick={() => setShowModal(true)}
                  >
                    ADD NEW ROOM
                  </Button>
                </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <RoomIntakeFormListTable
                allRooms={allRooms}
                increaseOccupiedRooms={increaseOccupiedRooms}
            />
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default RoomIntakeForm