import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import useMounted from "../../../hooks/useMounted";
import {
  createDesignation,
  editDesignation,
} from "../../../api/admin/Designation";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const EditDesignationModal = (props) => {
  const {
    designationData,
    editDesignationModalState,
    handleEditDesignationModalClose,
    getDesignations,
  } = props;

  const mounted = useMounted();
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={editDesignationModalState}
        onClose={handleEditDesignationModalClose}
      >
        <Formik
          initialValues={{
            name: designationData?.name || "",
            short_name: designationData?.short_name || "",
            description: designationData?.description || "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .max(255)
              .required("Designation Name is required"),
            short_name: Yup.string()
              .max(255)
              .required("Short Name is required"),
            description: Yup.string().max(255).nullable(),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const data = {
                ...values,
                id: designationData?.id,
              };
              const res = await editDesignation(data);
              if (res?.data) {
                toast.success("Designation modified successfully");
                handleEditDesignationModalClose();
                getDesignations();
              }
              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              toast.error("Something went wrong");
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form
              noValidate
              onSubmit={handleSubmit}
              autocomplete="off"
              {...props}
            >
              <DialogTitle>Create New Designation</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Designation Name *"
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Short Name *"
                      error={Boolean(touched.short_name && errors.short_name)}
                      fullWidth
                      helperText={touched.short_name && errors.short_name}
                      margin="normal"
                      name="short_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.short_name}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Description"
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      helperText={touched.description && errors.description}
                      margin="normal"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                  <Box sx={{ ml: 1 }}>
                    <Button
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      onClick={handleEditDesignationModalClose}
                      sx={{ backgroundColor: "#C0CBDE" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default EditDesignationModal;
