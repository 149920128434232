import React from "react";
import Chart from "react-apexcharts";

const BurnDownChart = ({ data }) => {
  const options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      title: {
        text: "Remaining Points",
      },
      min: 0,
    },
    markers: {
      size: 5,
    },
  };

  const series = data?.map((sprint) => ({
    name: `Burn down chart of ${sprint.name}`,
    data: Object.keys(sprint?.burn_down).map((key) => ({
      x: new Date(key).getTime(),
      y: sprint?.burn_down[key],
    })),
  }));

  return <Chart options={options} series={series} type="line" height={350} />;
};

export default BurnDownChart;
