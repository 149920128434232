import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import PlusIcon from "../../icons/Plus";
import CreateNewDesignationModal from "../../components/dashboard/admin/CreateNewDesignation";
import { getAllDesignations } from "../../api/admin/Designation";
import EditDesignationModal from "../../components/dashboard/admin/EditDesignation";
import PencilAlt from "../../icons/PencilAlt";

const ManageDesignation = () => {
  const { settings } = useSettings();
  const [designationModalState, setDesignationModalState] = useState(false);
  const [editDesignationModalState, setEditDesignationModalState] =
    useState(false);
  const [designations, setDesignations] = useState([]);

  useEffect(() => {
    getDesignations();
  }, []);

  const handleCreateNewDesignationModalOpen = () => {
    setDesignationModalState(true);
  };

  const handleCreateNewDesignationModalClose = () => {
    setDesignationModalState(false);
  };

  const handleEditDesignationModalOpen = () => {
    setEditDesignationModalState(true);
  };

  const handleEditDesignationModalClose = () => {
    setEditDesignationModalState(false);
  };

  const getDesignations = async () => {
    const res = await getAllDesignations();
    if (res.data) {
      setDesignations(res.data);
    }
  };

  return (
    <>
      {designationModalState && (
        <CreateNewDesignationModal
          handleCreateNewDesignationModalClose={
            handleCreateNewDesignationModalClose
          }
          designationModalState={designationModalState}
          getDesignations={getDesignations}
        />
      )}
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "80vh",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Manage Designation
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                Manage Designation
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={handleCreateNewDesignationModalOpen}
                >
                  Create Designation
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3, width: "100%" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Designation</TableCell>
                  <TableCell align="left">Short Name</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {designations.map((designation) => (
                  <>
                    <EditDesignationModal
                      handleEditDesignationModalClose={
                        handleEditDesignationModalClose
                      }
                      editDesignationModalState={editDesignationModalState}
                      designationData={designation}
                      getDesignations={getDesignations}
                    />
                    <TableRow key={designation.id}>
                      <TableCell align="left">{designation.name}</TableCell>
                      <TableCell align="left">
                        {designation.short_name}
                      </TableCell>
                      <TableCell align="left">
                        {designation.description}
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          color="primary"
                          onClick={handleEditDesignationModalOpen}
                          disabled={designation.is_global}
                        >
                          <PencilAlt fontSize="small" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ManageDesignation;
