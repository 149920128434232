import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Pagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import SingleMilestone from "./SingleMilestone";
import AddNewMilestoneModal from "./AddNewMilestoneModal";
import { getAllProjectMilestones } from "../../../api/project/Project";
import useLoader from "../../../hooks/useLoader";
// import VideoFloatButton from "../../VideoFloatButton";

const Milestones = ({
  selectedProject,
  showAddMilestoneModal,
  setShowAddMilestoneModal,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [milestones, setMilestones] = useState([]);

  const { loaderState, loaderStart, loaderEnd } = useLoader();
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  useEffect(() => {
    getMileStones();
  }, []);

  const getMileStones = async () => {
    loaderStart();
    const res = await getAllProjectMilestones({
      projectId: selectedProject.id,
      page: currentPage,
    });

    if (res.data.success) {
      setMilestones(res.data.data.milestones);
    }
    loaderEnd();
  };
  return (
    <Box sx={{ pt: 3 }}>
      {/* <VideoFloatButton tutorialName="milestones" /> */}
      {showAddMilestoneModal && (
        <AddNewMilestoneModal
          selectedProject={selectedProject}
          showAddMilestoneModal={showAddMilestoneModal}
          setShowAddMilestoneModal={setShowAddMilestoneModal}
          getMileStones={getMileStones}
        />
      )}
      <Table>
        <TableHead>
          <TableRow sx={{ th: { fontWeight: "700", fontSize: "13px" } }}>
            <TableCell>#</TableCell>
            <TableCell>MILESTONE NAME</TableCell>
            <TableCell align="center">COLOR</TableCell>
            <TableCell>TYPE</TableCell>
            <TableCell>BUDGET</TableCell>
            <TableCell>ACTUAL</TableCell>
            <TableCell>NOTES</TableCell>
            <TableCell align="center">ACTION</TableCell>
          </TableRow>
        </TableHead>
        {milestones?.data?.length > 0 && (
          <TableBody>
            {milestones?.data.map((milestone, index) => {
              return (
                <SingleMilestone
                  milestone={milestone}
                  key={index}
                  index={index}
                  getMileStones={getMileStones}
                />
              );
            })}
          </TableBody>
        )}
      </Table>
      <Box sx={{ py: 4, display: "flex", justifyContent: "center" }}>
        {milestones?.data?.length > 0 ? (
          <Pagination
            page={currentPage}
            onChange={handleChange}
            count={milestones.last_page}
          />
        ) : (
          <Typography variant="h6" color="secondary">
            {loaderState ? "Loading..." : "No Records Available"}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Milestones;
