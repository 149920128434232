export const columnsFromBackend = {
  backlog: {
    title: "Backlog",
    tasks: [],
    colors: ["#F075", "#FF6Fcc22"],
  },
  "to-dos": {
    title: "To Dos",
    tasks: [],
    colors: ["#29B6F633", "#00ACC133"],
  },
  "in-progress": {
    title: "In Progress",
    tasks: [],
    colors: ["#FF980333", "#FF572233"],
  },
  "q/a": {
    title: "Q/A",
    tasks: [],
    colors: ["#9C27B033", "#673AB733"],
  },
  done: {
    title: "Done",
    tasks: [],
    colors: ["#4CAF5033", "#388E3C33"],
  },
};
