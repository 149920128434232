import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { handleApiRequest } from "../../hooks/callApi";

export default function AddSprint({ refetchSprints }) {
  const { projectId } = useParams();
  const [open, setOpen] = useState(false);
  const [sprintData, setSprintData] = useState({
    name: "",
    goal: "",
    start_date: "",
    end_date: "",
    score: "",
  });

  const [errors, setErrors] = useState({
    start_date: "",
    end_date: "",
  });

  const handleInputChange = (e) => {
    setSprintData({
      ...sprintData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveSprint = async () => {
    // Reset errors
    setErrors({
      start_date: "",
      end_date: "",
    });

    let hasError = false;
    const { start_date, end_date, score } = sprintData;

    // Convert dates to JavaScript Date objects for validation
    const start = new Date(start_date);
    const end = new Date(end_date);

    // Validate start and end dates
    if (start > end) {
      setErrors((prev) => ({
        ...prev,
        end_date: "End date must be after start date",
      }));
      hasError = true;
    }

    if (end < new Date()) {
      setErrors((prev) => ({
        ...prev,
        end_date: "End date cannot be in the past",
      }));
      hasError = true;
    }

    if (!hasError) {
      const response = await handleApiRequest("/api/sprints", "post", {
        ...sprintData,
        project_id: projectId,
      });
      console.log("response", response);
      await refetchSprints?.();
      setOpen(false);
    }
  };

  return (
    <>
      <Button
        startIcon={<Add />}
        size="small"
        color="primary"
        variant="contained"
        onClick={() => setOpen(true)}
        sx={{ m: 1 }}
      >
        ADD NEW SPRINT
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add New Sprint</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Sprint Name"
                name="name"
                value={sprintData.name}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Sprint Goal"
                name="goal"
                value={sprintData.goal}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Start Date"
                name="start_date"
                type="date"
                value={sprintData.start_date}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                fullWidth
                error={!!errors.start_date}
                helperText={errors.start_date}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="End Date"
                name="end_date"
                type="date"
                value={sprintData.end_date}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                fullWidth
                error={!!errors.end_date}
                helperText={errors.end_date}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Maximum Score"
                name="score"
                value={sprintData.score}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveSprint}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
