import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import toast from "react-hot-toast";
import { generateShareLink } from "../../../api/share/Share";
import useLoader from "../../../hooks/useLoader";
import AllTasksGanttChartView from "./AllTasksGanttChartView";
import MonthWiseBudget from "./budget/MonthWiseBudget";
// import CostGraph from "./dashboard/CostGraph";
import HealthGraph from "./dashboard/HealthGraph";
import PeopleUtilization from "./dashboard/PeopleUtilization";
// import ProgressGraph from "./dashboard/ProgressGraph";
import TaskGraph from "./dashboard/TaskGraph";
// import TimeGraph from "./dashboard/TimeGraph";
import SprintDashboard from "../../../Pages/sprint/Dashboard";
const ProjectDashboard = ({ selectedProject }) => {
  const { loaderState, loaderStart, loaderEnd } = useLoader();

  const generateLink = async () => {
    loaderStart();
    const res = await generateShareLink(selectedProject.id);
    if (res.data.data.token) {
      const link = `${process.env.REACT_APP_FRONTEND_URL}/share/project/${res.data.data.token}`;
      navigator.clipboard.writeText(link);
      toast.success(`Link copied ${link}`, {
        duration: 8000,
        style: {
          maxWidth: 450,
          wordBreak: "break-all",
        },
      });
    }
    loaderEnd();
  };
  console.log({ selectedProject });
  return (
    <>
      {selectedProject.workflow_type === "agile" ? (
        <SprintDashboard />
      ) : selectedProject?.taskCount > 0 ? (
        <>
          <Box sx={{ float: "right" }}>
            <Tooltip title="Share project dashboard">
              <Button
                onClick={generateLink}
                variant="contained"
                color="primary"
              >
                Share
              </Button>
            </Tooltip>
          </Box>
          <Grid container spacing={2}>
            <SingleProjectDashboard
              title="Health"
              styles={{ overflowY: "auto", height: 460 }}
            >
              <HealthGraph selectedProject={selectedProject.id} />
            </SingleProjectDashboard>

            {/* <SingleProjectDashboard
              title="Progress"
              styles={{ pr: 1, height: 460 }}
            >
              <ProgressGraph selectedProject={selectedProject.id} />
            </SingleProjectDashboard> */}

            {/* <SingleProjectDashboard title="Time" styles={{ px: 1 }}>
              <TimeGraph selectedProject={selectedProject.id} />
            </SingleProjectDashboard> */}

            <SingleProjectDashboard title="Task" styles={{ px: 1 }}>
              <TaskGraph selectedProject={selectedProject.id} />
            </SingleProjectDashboard>

            {/* <SingleProjectDashboard title="Cost" styles={{ px: 1 }}>
              <CostGraph selectedProject={selectedProject.id} />
            </SingleProjectDashboard> */}

            {/* <SingleProjectDashboard title="Workload" styles={{ px: 1 }}>
              <WorkloadGraph selectedProject={selectedProject.id} />
            </SingleProjectDashboard> */}
            <SingleProjectDashboard
              title="Project gantt chart"
              styles={{ px: 1 }}
              md={12}
              sm={12}
              xs={12}
            >
              <AllTasksGanttChartView selectedProject={selectedProject} />
            </SingleProjectDashboard>
          </Grid>
          <SingleProjectDashboard
            title="Project Detailed Budget"
            styles={{
              px: 1,
              maxHeight: "600px",
              overflowY: "auto",
            }}
            md={12}
            sm={12}
            xs={12}
          >
            {/* Show detailed budget view */}
            <MonthWiseBudget
              selectedProject={selectedProject}
              showOnlyDetailedView={true}
            />
          </SingleProjectDashboard>
          <SingleProjectDashboard
            title="Member Utilization"
            styles={{
              px: 1,
              maxHeight: "600px",
              overflowY: "auto",
              mt: 2,
            }}
            md={12}
            sm={12}
            xs={12}
          >
            {/* Show detailed budget view */}
            <PeopleUtilization selectedProject={selectedProject} />
          </SingleProjectDashboard>
        </>
      ) : (
        <Box sx={{ py: 4, display: "flex", justifyContent: "center" }}>
          <Typography variant="h6" color="secondary">
            {loaderState
              ? "Loading..."
              : "Insufficient data to generate dashboard"}
          </Typography>
        </Box>
      )}
    </>
  );
};

const SingleProjectDashboard = ({
  title,
  styles = {},
  sm,
  xs,
  md,
  children,
}) => {
  return (
    <>
      <Grid item xs={xs || 12} sm={sm || 6} md={md || 6}>
        <Card
          sx={{
            boxShadow: "3px 3px 10px #00000050 !important",
            ...styles,
          }}
        >
          <CardHeader title={title} />
          {children}
        </Card>
      </Grid>
    </>
  );
};

export default ProjectDashboard;
