import React, { useEffect, useState } from "react";
import { gantt } from "dhtmlx-gantt";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import "dhtmlx-gantt/codebase/dhtmlxgantt.js";
import { editTask } from "../../../api/project/Project";
import moment from "moment";
import ViewAndEditTaskModal from "./ViewAndEditTaskModal";
import { Box, Button, MenuItem, Select } from "@material-ui/core";
import AddNewTaskModal from "./AddNewTaskModal";
var textEditor = { type: "text", map_to: "name" };

const scales = {
  day: [{ unit: "day", step: 1, format: "%d, %D" }],
  week: [
    { unit: "week", step: 1, format: "%W" },
    { unit: "day", step: 1, format: "%d, %D" },
  ],
  month: [
    { unit: "month", step: 1, format: "%F, %Y" },
    { unit: "week", step: 1, format: "%W" },
  ],
  quarter: [
    // { unit: "year", step: 1, format: "%Y" },
    { unit: "quarter", step: 1, format: "%M" },
    { unit: "month", step: 1, format: "%F, %Y" },
    // { unit: "week", step: 1, format: "%W" },
    // { unit: "day", step: 1, format: "%d, %D" },
  ],
  year: [
    { unit: "year", step: 1, format: "%Y" },
    { unit: "quarter", step: 1, format: "%M" },
    //   { unit: "month", step: 1, format: "%F, %Y" },
    //   { unit: "week", step: 1, format: "%W" },
    //   { unit: "day", step: 1, format: "%d, %D" },
  ],
};

const GanttChart = ({
  allTasks,
  getTaskList,
  selectedProject,
  setaddAssignedToProjectMembers,
}) => {
  const [selectedTask, setSelectedTask] = useState(null);
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [scale, setScale] = useState("day");

  useEffect(() => {
    if (selectedTask) {
      setShowEditTaskModal(true);
    }
  }, [selectedTask]);

  useEffect(() => {
    // Initialize Gantt chart
    gantt.config.xml_date = "%Y-%m-%d %H:%i";
    // Customize tooltip
    gantt.templates.tooltip_text = function (start, end, task) {
      return `
      <div class="tooltip-title">
         <div class="tooltip-task-name"> <b>Task:</b>${task.name} </div>
         <br/><div class="tooltip_assigned_to"><b>Assigned To:</b> ${
           task?.get_assigned_to?.first_name || "None"
         } ${task?.get_assigned_to?.last_name || ""} </div>
         Start date: <b> ${moment(start).format(
           "MM-DD-YYYY"
         )} </b><br/>End date:<b>${moment(end).format(
        "MM-DD-YYYY"
      )}</b>  </div>`;
    };

    const colors = ["#FFA07A", "#20B2AA", "#778899", "#9370DB", "#FF69B4"];

    gantt.attachEvent("onTaskLoading", function (task) {
      const index = allTasks.findIndex((t) => t.id === task.id);
      if (index !== -1) {
        task.color = colors[index % colors.length];
      }
      return true;
    });

    gantt.config.grid_resize = true;
    gantt.config.drag_timeline = true;
    gantt.config.grid_elastic_columns = "min_width";

    gantt.init("gantt-container");

    // Define Gantt chart structure
    gantt.config.columns = [
      {
        name: "name",
        label: "Task name",
        align: "left",
        width: "*",
        min_width: 100,
        resizes: true,
        template: function (task) {
          return `<div style="font-size: 10px;" class="task-name" data-column="name">${task.name}</div>`;
        },
        // resize: true,
        // editor: textEditor,
      },
      {
        name: "start_date",
        label: "Start Date",
        align: "center",
        width: 70,
        template: function (task) {
          return `<span class="start_date text-center" style="font-size: 10px;">${moment(
            task.start_date
          ).format("MM-DD-YY")}</span>`;
        },
      },
      {
        name: "end_date",
        label: "End Date",
        align: "center",
        template: function (task) {
          return `<span class="start_date text-center" style="font-size: 10px;">${moment(
            task.end_date
          ).format("MM-DD-YY")}</span>`;
        },
      },
      {
        name: "status",
        label: "Status",
        align: "center",
        width: 70,
        template: function (task) {
          return `<span class="status text-center" style="font-size: 10px;">${
            task.status === 1
              ? "TO DO"
              : task.status === 2
              ? "In Progress"
              : task.status === 3
              ? "Completed"
              : task.status === 4
              ? "On Hold"
              : "Closed"
          }</span>`;
        },
      },
      {
        name: "get_assigned_to",
        label: "Assigned To",
        align: "left",
        width: "*",
        min_width: 100,
        resizes: true,
        template: function (task) {
          return `<div style="font-size: 10px;" title="${
            task?.get_assigned_to?.first_name || ""
          } ${task?.get_assigned_to?.last_name || ""}" class="assigned_to">${
            task?.get_assigned_to?.first_name || ""
          } ${task?.get_assigned_to?.last_name || ""}</div>`;
        },
      },
    ];

    // Create empty row to add new task
    gantt.locale.labels.new_task = "Add new task";

    // Handle click on the task
    gantt.attachEvent("onTaskClick", function (id, e) {
      console.log("Task", id, "was clicked");
    });

    // Handle double click on the task
    gantt.attachEvent("onTaskDblClick", function (id, e) {
      console.log("Task", id, "was double clicked");
      const task = allTasks.filter((task) => task.id === +id)[0];
      setSelectedTask(task);
    });

    // Make the config columns scrollable
    gantt.config.scroll_size = 10;
    gantt.config.grid_width = 400;
    // Make the grid width draggable
    gantt.config.sort = true;

    // Show tooltip
    gantt.config.show_progress = true;

    // ADD elastic columns
    // gantt.config.grid_elastic_columns = true;

    // Show full screen button
    gantt.config.layout = {
      css: "gantt_container",
      rows: [
        {
          cols: [
            {
              view: "grid",
            },
            { resizer: true, width: 1 },
            {
              view: "timeline",
            },
          ],
          gravity: 2,
        },
        { resizer: true, width: 1 },
      ],
    };

    // Show month, week, day buttons
    gantt.config.scales = [
      { unit: "month", step: 1, format: "%F, %Y" },
      {
        unit: "week",
        step: 1,
        format: function (date) {
          var dateToStr = gantt.date.date_to_str("%d %M");
          var endDate = gantt.date.add(date, -6, "day");
          var weekNum = gantt.date.date_to_str("%W")(date);
          return (
            "#" + weekNum + ", " + dateToStr(date) + " - " + dateToStr(endDate)
          );
        },
      },
      { unit: "day", step: 1, format: "%d, %D" },
    ];

    // Customize progress bar content
    gantt.templates.progress_text = function (start, end, task) {
      console.log("task", task);
      return "<span style='text-align:center;'>" + 50 + "% </span>";
    };

    // Customize task text
    gantt.templates.task_text = function (start, end, task) {
      const formattedStart = moment(start).format("YYYY-MM-DD");
      const formattedEnd = moment(end).format("YYYY-MM-DD");
      return formattedStart + " : " + formattedEnd;
    };

    // Customize task class
    gantt.templates.task_class = function (start, end, task) {
      return "task-" + task.id;
    };
    // DHTMLX Gantt chart cell width

    // Enable drag-and-drop functionality
    gantt.attachEvent("onAfterTaskDrag", (id, mode, task, original) => {
      // Update task dates
      const start = gantt.getTask(id).start_date;
      const end = gantt.getTask(id).end_date;
      editTaskDate(id, start, end);
      console.log(
        "Task",
        id,
        "was dragged. New start:",
        start,
        "New end:",
        end
      );
      // Here, you can update the task dates in your state or make API calls
    });

    gantt.plugins({
      fullscreen: true,
      tooltip: true,
    });

    gantt.parse({
      data: allTasks,
    });

    // setTimeout(() => {
    //   applyTaskColors();
    // }, 1000);
  }, [allTasks]);

  useEffect(() => {
    setScaleConfig();
  }, [scale]);

  const editTaskDate = async (id, start_date, end_date) => {
    const task = allTasks.filter((task) => task.id === +id)[0];

    let data = new FormData();
    data.append("task_doc", null);
    data.append("task_id", id);
    data.append("proj_id", task.proj_id);
    data.append("task_name", task.name);
    data.append("task_type", task.get_type.id || "");
    data.append("task_skills", task.skills || "");
    data.append("task_estimate", task.estimate_hours);
    data.append("actual_hours", task.actual_hours || "");
    data.append("dependent_task_id", task.dependency || "");
    data.append("person_id", task.get_assigned_to.id);
    data.append("start_date", moment(start_date).format("YYYY/MM/DD/"));
    data.append("end_date", moment(end_date).format("YYYY/MM/DD/"));
    data.append("task_milestone", task.milestone || "");
    data.append("task_priority", task.priority || "");
    data.append("task_status", task.get_status.id || "");
    data.append("desc", task.description || "");
    data.append("taskDocId", "");
    const res = await editTask(data, id);
    if (res.data.success) {
      getTaskList();
    }
  };

  const toggleFullScreen = () => {
    document.querySelector(".gantt_container").requestFullscreen();
  };

  function setScaleConfig() {
    gantt.config.scales = scales[scale];

    const value = scale;
    gantt.render();
  }

  const applyTaskColors = () => {
    const colors = ["#FFA07A", "#20B2AA", "#778899", "#9370DB", "#FF69B4"];
    allTasks.forEach((task, index) => {
      const taskElement = document.querySelector(`.task-${task.id}`);
      console.log("taskElement", taskElement);
      if (taskElement) {
        taskElement.style.backgroundColor = colors[index % colors.length];
      }
    });
  };
  return (
    <>
      <Select value={scale} onChange={(e) => setScale(e.target.value)}>
        <MenuItem value={"day"}>Day</MenuItem>
        <MenuItem value={"week"}>Week</MenuItem>
        <MenuItem value={"month"}>Month</MenuItem>
        <MenuItem value={"quarter"}>Quarter</MenuItem>
        <MenuItem value={"year"}>Year</MenuItem>
      </Select>
      {/* <Button variant="contained" onClick={toggleFullScreen}>
        Full Screen
      </Button> */}
      {showAddTaskModal && (
        <AddNewTaskModal
          selectedProject={selectedProject}
          showAddTaskModal={showAddTaskModal}
          setShowAddTaskModal={setShowAddTaskModal}
          getTaskList={getTaskList}
          setaddAssignedToProjectMembers={setaddAssignedToProjectMembers}
        />
      )}
      {showEditTaskModal && (
        <ViewAndEditTaskModal
          task={selectedTask}
          showEditTaskModal={showEditTaskModal}
          setShowEditTaskModal={setShowEditTaskModal}
          selectedProject={selectedProject}
          getTaskList={getTaskList}
          setaddAssignedToProjectMembers={setaddAssignedToProjectMembers}
        />
      )}
      <div
        style={{
          width: "100%",
          height: "500px",
          overflowY: "auto",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            left: "0px",
            zIndex: "1000",
            cursor: "pointer",
            color: "#000",
            fontSize: "18px",
            textAlign: "center",
            backgroundColor: "primary.main",
            fontWeight: "bolder",
            px: 0.5,
            py: 0.5,
            "&:hover": {
              backgroundColor: "primary.dark",
              color: "#fff",
            },
          }}
          onClick={() => setShowAddTaskModal(true)}
        >
          +
        </Box>
        <div
          id="gantt-container"
          style={{ width: "100%", height: "100%" }}
        ></div>
      </div>
    </>
  );
};

export default GanttChart;
