import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Pagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import SingleMember from "./SingleMember";
import CreateNewMemberModal from "./CreateNewMemberModal";
import useProject from "../../../hooks/useProject";
import useLoader from "../../../hooks/useLoader";

const Members = ({
  selectedProject,
  showAddMemberModal,
  setShowAddMemberModal,
}) => {
  const handleChange = (event, value) => {
    getAllProjectMembers(selectedProject.id, value);
  };
  const { loaderState } = useLoader();

  const { getAllProjectMembers, allProjectMembers } = useProject();

  useEffect(() => {
    getAllProjectMembers(selectedProject.id);
  }, []);

  return (
    <Box sx={{ pt: 3 }}>
      {showAddMemberModal && (
        <CreateNewMemberModal
          showAddMemberModal={showAddMemberModal}
          setShowAddMemberModal={setShowAddMemberModal}
          selectedProject={selectedProject}
        />
      )}
      <Table>
        <TableHead>
          <TableRow sx={{ th: { fontWeight: "700", fontSize: "13px" } }}>
            <TableCell>#</TableCell>
            <TableCell>MEMBER NAME</TableCell>
            <TableCell>ROLE</TableCell>
            <TableCell>DEPARTMENT</TableCell>
            <TableCell>DAILY HOURS</TableCell>
            {/* <TableCell>BOOKING %</TableCell> */}
            {/* <TableCell align="center">HOURLY RATE ($)</TableCell> */}
            {/* <TableCell>PROJECTS ASSIGNED</TableCell> */}
            <TableCell align="center">ACTION</TableCell>
          </TableRow>
        </TableHead>
        {allProjectMembers?.data?.length > 0 && (
          <TableBody>
            {allProjectMembers?.data.map((member, index) => {
              return (
                <SingleMember
                  member={member}
                  key={index}
                  index={index}
                  selectedProject={selectedProject}
                />
              );
            })}
          </TableBody>
        )}
      </Table>
      <Box sx={{ py: 4, display: "flex", justifyContent: "center" }}>
        {allProjectMembers?.data?.length > 0 ? (
          <Pagination
            page={allProjectMembers.current_page}
            onChange={handleChange}
            count={allProjectMembers.last_page}
          />
        ) : (
          <Typography variant="h6" color="secondary">
            {loaderState ? "Loading..." : "No Records Available"}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Members;
