import { Dialog, Fab, Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";
import { School } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import useCommons from "../hooks/useCommons";
import Draggable from "react-draggable";

const FloatButtonWrapper = ({ url, modalOpen, handleModalClose, children }) => {
  return (
    <>
      {modalOpen && (
        <Dialog open={modalOpen} onClose={handleModalClose}>
          <iframe
            width="560"
            height="315"
            src={url}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Dialog>
      )}
      {children}
    </>
  );
};

const VideoFloatButton = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [url, setUrl] = useState(null);
  const { tutorials, currentPath, updateCurrentPath } = useCommons();

  const location = useLocation();

  const [position, setPosition] = useState({ x: 1000, y: 700 });

  useEffect(() => {
    // Retrieve position from local storage on mount
    const savedPosition = JSON.parse(localStorage.getItem("fabPosition"));
    if (savedPosition) {
      setPosition(savedPosition);
    }
  }, []);

  const handleDrag = (e, data) => {
    console.log(data);
    const newPosition = { x: data.x, y: data.y };
    setPosition(newPosition);
    localStorage.setItem("fabPosition", JSON.stringify(newPosition));
  };

  useEffect(() => {
    updateCurrentPath();
  }, [location]);

  useEffect(() => {
    if (tutorials?.length > 0) {
      updateUrl();
    }
  }, [currentPath, tutorials]);

  const updateUrl = () => {
    // const tutorialUrl = tutorials.filter((tutorial) => {
    //   alert(matchUrl(currentPath, tutorial.tutorial_name));
    //   return matchUrl(currentPath, tutorial.tutorial_name);
    // });
    let tutorialUrl = null;
    let i = 0;
    while (i < tutorials.length) {
      const doMatch = matchUrl(currentPath, tutorials[i].tutorial_name);
      if (doMatch) {
        tutorialUrl = tutorials[i].tutorial_url;
        break;
      }
      i++;
    }
    setUrl(tutorialUrl);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const matchUrl = (currentURL, storedURL) => {
    if (currentURL && currentURL.length) {
      const urlWithoutDomain = currentURL
        .split(process.env.REACT_APP_FRONTEND_URL)
        .pop();
      const route = urlWithoutDomain.lastIndexOf("/");
      const commonRoute = urlWithoutDomain.substring(0, route);
      const variableRoute = urlWithoutDomain.substring(route + 1);

      let normalURL = null;
      let dynamicURL = null;
      let param = null;
      let queryParams = null;

      if (variableRoute.includes("?")) {
        const varibaleRouteArr = variableRoute.split("?");
        param = varibaleRouteArr[0];
        queryParams = varibaleRouteArr[1];
        normalURL = `${commonRoute}/${param}?${queryParams}`;
        dynamicURL = `${commonRoute}/[*]?${queryParams}`;
      } else {
        param = variableRoute;
        normalURL = `${commonRoute}/${param}`;
        dynamicURL = `${commonRoute}/[*]`;
      }

      // console.log("normalURL", normalURL);

      if (storedURL.includes("[*]") && storedURL === dynamicURL) {
        return true;
      } else if (storedURL === normalURL) {
        return true;
      }

      return false;
    }
  };

  return (
    <>
      {url && (
        <Draggable position={position} onStop={handleDrag}>
          <div style={fabStyle}>
            <FloatButtonWrapper
              url={url}
              modalOpen={modalOpen}
              handleModalClose={handleModalClose}
            >
              <Tooltip onClick={handleModalOpen} title="Video Tutorial">
                <Fab
                  color="primary"
                  size="medium"
                  sx={{
                    bottom: 0,
                    margin: (theme) => theme.spacing(2),
                    position: "fixed",
                    right: 0,
                    zIndex: (theme) => theme.zIndex.speedDial,
                  }}
                >
                  <School fontSize="medium" />
                </Fab>
              </Tooltip>
            </FloatButtonWrapper>
          </div>
        </Draggable>
      )}
    </>
  );
};

export default VideoFloatButton;

const fabStyle = {
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  zIndex: 991000,
};
