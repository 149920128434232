import React, { useState } from "react";
import TrashIcon from "../../../icons/Trash";
import PencilAltIcon from "../../../icons/PencilAlt";
import { Link, TableCell, TableRow } from "@material-ui/core";
import { deleteProjectMember } from "../../../api/project/Project";
import toast from "react-hot-toast";
import EditMemberModal from "./EditMemberModal";
import roles from "../../../@const/roles";
import useProject from "../../../hooks/useProject";
import DeleteModal from "../../DeleteModal";
const SingleMember = ({ member, index, selectedProject }) => {
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const { getAllProjectMembers } = useProject();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const removeProjectMember = async () => {
    const res = await deleteProjectMember(member?.id);
    if (res.data.success) {
      getAllProjectMembers(selectedProject.id);
      toast.success("Member deleted");
    }
  };
  if (!member?.get_member_details) return <></>;
  return (
    <>
      <DeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        onYesClick={removeProjectMember}
        warningMessage="Are you sure you want to remove this member from the project?"
      />
      {showAddMemberModal && (
        <EditMemberModal
          showAddMemberModal={showAddMemberModal}
          setShowAddMemberModal={setShowAddMemberModal}
          selectedProject={selectedProject}
          member={member}
        />
      )}
      <TableRow hover>
        <TableCell>{++index}</TableCell>
        <TableCell
          onClick={() => setShowAddMemberModal(true)}
          sx={{ cursor: "pointer" }}
        >
          {`${member?.get_member_details.first_name} ${member.get_member_details.last_name}`}
        </TableCell>
        <TableCell>
          {
            roles.filter(
              (role) => role.roleType === member?.get_member_details.role
            )[0].roleValue
          }
        </TableCell>
        <TableCell>
          {member?.get_member_details?.extra_params?.get_department
            ?.department || "_"}
        </TableCell>
        <TableCell>{member?.time_on_proj}</TableCell>
        {/* <TableCell>{member.booking}</TableCell> */}
        {/* <TableCell align="center">
          {member?.get_member_details?.extra_params?.hourly_rate}
        </TableCell> */}
        {/* <TableCell>{member.name}</TableCell> */}
        <TableCell align="center">
          <PencilAltIcon
            className="action-icon"
            onClick={() => setShowAddMemberModal(true)}
          />
          <TrashIcon
            onClick={() => setShowDeleteModal(true)}
            className="action-icon"
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default SingleMember;
