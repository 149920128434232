import React, { useEffect } from "react";
import { gantt } from "dhtmlx-gantt";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import moment from "moment";

const GanttChart = ({ data }) => {
  useEffect(() => {
    // Set up Gantt chart configuration
    gantt.config.xml_date = "%Y-%m-%d %H:%i";

    gantt.config.columns = [
      { name: "text", label: "Task Name", width: "*", align: "left" },
      { name: "start_date", label: "Start Date", align: "center" },
      { name: "end_date", label: "End Date", align: "center" },
    ];

    // Initialize the Gantt chart in a specific container
    gantt.init("gantt-container");

    // Transform the data to match DHTMLX Gantt format
    const tasks = data.map((sprint) => ({
      id: sprint.id, // Ensure each task has a unique ID
      text: sprint.name,
      start_date: moment(sprint.start_date).format("YYYY-MM-DD"),
      end_date: moment(sprint.end_date).format("YYYY-MM-DD"),
    }));

    // Parse the data into the Gantt chart
    gantt.parse({ data: tasks });
  }, [data]);

  return (
    <div
      id="gantt-container"
      style={{ width: "100%", height: "500px", overflowY: "auto" }}
    ></div>
  );
};

export default GanttChart;
