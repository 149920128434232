import ContactCompanyAdmin from "./Pages/ContactCompanyAdmin";
import Home from "./Pages/Home";
import NotFound from "./Pages/NotFound";
import Test from "./Pages/Test";
import AddNewTiming from "./Pages/Time/AddNewTiming";
import PendingTimeRequests from "./Pages/Time/PendingTimeRequests";
import Archive from "./Pages/admin/Archive";
import CompanyAccessLevel from "./Pages/admin/CompanyAccessLevel";
import NewRegistrations from "./Pages/admin/NewRegistrations";
import ProjectTemplate from "./Pages/admin/ProjectTemplate";
import RequestedUser from "./Pages/admin/RequestedUser";
import StagesSetting from "./Pages/admin/StagesSetting";
import ViewAllCredentials from "./Pages/admin/ViewAllCredentials";
import ForgotPassword from "./Pages/authentication/ForgotPassword";
import Login from "./Pages/authentication/Login";
import Register from "./Pages/authentication/Register";
import RequestPending from "./Pages/authentication/RequestPending";
import ResetPassword from "./Pages/authentication/ResetPassword";
import Budget from "./Pages/budget/Budget";
import Calender from "./Pages/calender/Calender";
import ViewAllDepartment from "./Pages/department/ViewAllDepartment";
import RoomIntakeForm from "./Pages/intake-form/RoomIntakeForm";
import ManageDesignation from "./Pages/admin/ManageDesignation";
import PeopleUtilization from "./Pages/people/PeopleUtilization";
import ViewAllPeople from "./Pages/people/ViewAllPeople";
import AddNewPortfolio from "./Pages/portfolios/AddNewPortfolio";
import ViewAllPortfolios from "./Pages/portfolios/ViewAllPortfolios";
import ViewAndEditPortfolio from "./Pages/portfolios/ViewAndEditPortfolio";
import AddNewProject from "./Pages/projects/AddNewProject";
import MyTaskPortal from "./Pages/projects/MyTaskPortal";
import ProjectPreview from "./Pages/projects/ProjectPreview";
import ViewAllProjects from "./Pages/projects/ViewAllProjects";
import ViewAndEditProject from "./Pages/projects/ViewAndEditProject";
import QBHome from "./Pages/quickbooks/QBHome";
import QBLogin from "./Pages/quickbooks/QBLogin";
import CustomReport from "./Pages/reports/CustomReport";
import MilestoneHealth from "./Pages/reports/MilestoneHealth";
import ProjectReports from "./Pages/reports/ProjectReports";
import AvailableMembers from "./Pages/scheduling/AvailableMembers";
import PersonScheduling from "./Pages/scheduling/PersonScheduling";
import Scheduling from "./Pages/scheduling/Scheduling";
import UserTasks from "./Pages/scheduling/UserTasks";
import ViewAllScores from "./Pages/score/ViewAllScores";
import Secure from "./Pages/secure/Secure";
import SharedPortfolioDashboard from "./Pages/share/SharedPortfolioDashboard";
import SharedProjectDashboard from "./Pages/share/SharedProjectDashboard";
import SharedTaskList from "./Pages/share/SharedTaskList";
import Sprint from "./Pages/sprint/Sprint";
import Sprints from "./Pages/sprint/Sprints";
import AuthGuard from "./components/AuthGuard";
import GuestGuard from "./components/GuestGuard";
import MainLayout from "./components/MainLayout";
import Score from "./components/dashboard/projects/Score";
import AdminSuperAdminGuard from "./components/guards/AdminSuperAdminGuard";
import { QuickbooksProvider } from "./contexts/QuickbooksContext";

const routes = [
  {
    path: "quickbooks",
    children: [
      {
        path: "home",
        element: (
          <QuickbooksProvider>
            <QBHome />
          </QuickbooksProvider>
        ),
      },
      {
        path: "login",
        element: (
          <QuickbooksProvider>
            <QBLogin />
          </QuickbooksProvider>
        ),
      },
    ],
  },
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "register",
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: "request-pending",
        element: (
          <GuestGuard>
            <RequestPending />
          </GuestGuard>
        ),
      },
      {
        path: "forgot-password",
        element: (
          <GuestGuard>
            <ForgotPassword />
          </GuestGuard>
        ),
      },
      {
        path: "reset-password",
        element: (
          <GuestGuard>
            <ResetPassword />
          </GuestGuard>
        ),
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "admin-setting",
        children: [
          {
            path: "view-all-credentials",
            element: <ViewAllCredentials />,
          },
          {
            path: "new-registrations",
            element: <NewRegistrations />,
          },
          {
            path: "company-access-level",
            element: <CompanyAccessLevel />,
          },
          {
            path: "stages-setting",
            element: <StagesSetting />,
          },
          {
            path: "project-template",
            element: <ProjectTemplate />,
          },
          {
            path: "manage-designation",
            element: <ManageDesignation />,
          },
          {
            path: "archive",
            element: <Archive />,
          },
          {
            path: "requested-user",
            element: (
              <AdminSuperAdminGuard>
                <RequestedUser />
              </AdminSuperAdminGuard>
            ),
          },
        ],
      },
      {
        path: "portfolios",
        children: [
          {
            path: "view-all-portfolios",
            element: <ViewAllPortfolios />,
          },
          {
            path: "add-new-portfolio",
            element: <AddNewPortfolio />,
          },
          {
            path: "view-and-edit-portfolio/:portfolioId",
            element: <ViewAndEditPortfolio />,
          },
        ],
      },
      {
        path: "projects",
        children: [
          {
            path: "view-all-projects",
            element: <ViewAllProjects />,
          },
          {
            path: "add-new-project",
            element: <AddNewProject />,
          },
          {
            path: "my-task-portal",
            element: <MyTaskPortal />,
          },
          {
            path: "view-and-edit-project/:projectId",
            element: <ViewAndEditProject />,
          },
          {
            path: "view-and-edit-project/:projectId/sprints/:sprintId",
            element: <Sprint />,
          },
        ],
      },
      {
        path: "reports",
        children: [
          {
            path: "/",
            element: <ProjectReports />,
          },
          {
            path: "custom",
            element: <CustomReport />,
          },
        ],
      },
      {
        path: "room-intake-form",
        element: <RoomIntakeForm />,
      },
      {
        path: "peoples",
        children: [
          {
            path: "view-all",
            element: <ViewAllPeople />,
          },
          {
            path: "utilization",
            element: <PeopleUtilization />,
          },
        ],
      },
      {
        path: "preview/:projectId",
        element: <ProjectPreview />,
      },
      {
        path: "scheduling",
        children: [
          {
            path: "view-all",
            element: <Scheduling />,
          },
          {
            path: "members-available",
            element: <AvailableMembers />,
          },
          {
            path: "member/:memberId",
            element: <PersonScheduling />,
          },
          {
            path: "member/task/:memberId",
            element: <UserTasks />,
          },
        ],
      },
      {
        path: "departments",
        children: [
          {
            path: "view-all",
            element: <ViewAllDepartment />,
          },
        ],
      },
      {
        path: "time",
        children: [
          {
            path: "add-new",
            element: <AddNewTiming />,
          },
          {
            path: "approve",
            element: (
              <AdminSuperAdminGuard>
                <PendingTimeRequests />
              </AdminSuperAdminGuard>
            ),
          },
        ],
      },
      {
        path: "budget",
        element: <Budget />,
      },
      {
        path: "score",
        element: <ViewAllScores />,
      },
      {
        path: "add-score",
        element: <Score />,
      },
      {
        path: "score/:scoreID",
        element: <Score />,
      },
      {
        path: "calendar",
        element: <Calender />,
      },
      {
        path: "bikash/vkzsolzlosresfssbwkqwkjhldsstk",
        element: <Secure />,
      },
    ],
  },
  {
    path: "test",
    element: <Test />,
  },
  {
    path: "share",
    children: [
      {
        path: "project/:shareId",
        element: <SharedProjectDashboard />,
      },
      {
        path: "portfolio/:shareId",
        element: <SharedPortfolioDashboard />,
      },
      {
        path: "task/:shareId",
        element: <SharedTaskList />,
      },
    ],
  },
  {
    path: "un-authenticated",
    element: <NotFound />,
  },
  {
    path: "contact-company-admin",
    element: <ContactCompanyAdmin />,
  },
  {
    path: "milestoneHealth",
    element: <MilestoneHealth />,
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: (
          <AuthGuard>
            <Home />
          </AuthGuard>
        ),
      },
    ],
  },

  {
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
