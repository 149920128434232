import React, { useState } from "react";
import PencilAltIcon from "../../../icons/PencilAlt";
import TrashIcon from "../../../icons/Trash";
import { Link, TableCell, TableRow, Box, Tooltip } from "@material-ui/core";
import ViewAndEditTaskModal from "./ViewAndEditTaskModal";
import TaskTiming from "./TaskTiming";
import { deleteTask } from "../../../api/project/Project";
import toast from "react-hot-toast";
import moment from "moment";
import DeleteModal from "../../DeleteModal";
const SingleTask = ({
  selectedProject,
  allTasks,
  task,
  index,
  getTaskList,
  setaddAssignedToProjectMembers,
}) => {
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);
  const [showTaskTiming, setShowTaskTiming] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteProjectTask = async () => {
    const res = await deleteTask(task.id);
    if (res.data.success) {
      toast.success("Task deleted successfully");
      getTaskList();
    }
  };
  return (
    <>
      <DeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        onYesClick={deleteProjectTask}
        warningMessage="Are you sure you want to delete this task?"
      />
      {showTaskTiming && (
        <TaskTiming
          task={task}
          showTaskTiming={showTaskTiming}
          setShowTaskTiming={setShowTaskTiming}
          selectedProject={selectedProject}
          allTasks={allTasks}
        />
      )}
      {showEditTaskModal && (
        <ViewAndEditTaskModal
          task={task}
          showEditTaskModal={showEditTaskModal}
          setShowEditTaskModal={setShowEditTaskModal}
          selectedProject={selectedProject}
          allTasks={allTasks}
          getTaskList={getTaskList}
          setaddAssignedToProjectMembers={setaddAssignedToProjectMembers}
        />
      )}
      <TableRow hover>
        {/* <TableCell>{++index}</TableCell> */}
        <TableCell>
          <Link
            color="textPrimary"
            onClick={() => setShowEditTaskModal(true)}
            underline="none"
            variant="subtitle2"
          >
            {task.name}
          </Link>
        </TableCell>
        <TableCell>
          {task?.get_assigned_to
            ? `${task?.get_assigned_to?.first_name} ${task?.get_assigned_to?.last_name}`
            : "-"}
        </TableCell>
        {/* <TableCell>{task?.get_type?.type}</TableCell> */}
        <TableCell>
          <Tooltip title={task.get_status.status}>
            {task.status === 1 ? (
              <Box
                sx={{
                  height: "18px",
                  width: "18px",
                  borderRadius: "18px",
                  backgroundColor: "#ffbf00",
                  mx: "auto",
                }}
              ></Box>
            ) : task.status === 2 ? (
              <Box
                sx={{
                  height: "18px",
                  width: "18px",
                  borderRadius: "18px",
                  backgroundColor: "#7BDFE5",
                  mx: "auto",
                }}
              ></Box>
            ) : task.status === 3 ? (
              <Box
                sx={{
                  height: "18px",
                  width: "18px",
                  borderRadius: "18px",
                  backgroundColor: "#00ff00",
                  mx: "auto",
                }}
              ></Box>
            ) : task.status === 4 ? (
              <Box
                sx={{
                  height: "18px",
                  width: "18px",
                  borderRadius: "18px",
                  backgroundColor: "#ff0000",
                  mx: "auto",
                }}
              ></Box>
            ) : (
              <Box
                sx={{
                  height: "18px",
                  width: "18px",
                  borderRadius: "18px",
                  backgroundColor: "#00ff00",
                  mx: "auto",
                }}
              ></Box>
            )}
          </Tooltip>
        </TableCell>
        <TableCell>
          {moment(task.start_date, "YYYY-MM-DD").format("MM-DD-YYYY")}
        </TableCell>
        <TableCell>
          {" "}
          {moment(task.end_date, "YYYY-MM-DD").format("MM-DD-YYYY")}
        </TableCell>
        {/* <TableCell>{task.task_status}</TableCell> */}

        <TableCell align="center">
          {/* <PersonAddIcon className="action-icon" /> */}
          <Tooltip title="Edit">
            <PencilAltIcon
              className="action-icon"
              onClick={() => setShowEditTaskModal(true)}
            />
          </Tooltip>
          {/* <Tooltip title="Time log">
            <MoreTimeIcon
              className="action-icon"
              onClick={() => setShowTaskTiming(true)}
            />
          </Tooltip> */}
          <Tooltip title="Delete">
            <TrashIcon
              onClick={() => setShowDeleteModal(true)}
              className="action-icon"
            />
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
};

export default SingleTask;
