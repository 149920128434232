import { handleApiRequest } from "../../hooks/callApi";

export const getAllProjectsAPI = async (pageNum, projectType, projectName) => {
  const currentPage = pageNum || 1;

  const res = await handleApiRequest(
    `/api/project-search?page=${currentPage}&projType=${
      projectType || ""
    }&projName=${projectName || ""}`,
    "get"
  );
  return res;
};

export const myTaskPortal = async (sortBy) => {
  const res = await handleApiRequest(
    `/api/my-task-portal?sort_by=${sortBy || ""}`,
    "get"
  );
  return res;
};

export const importProject = async (data, id, override) => {
  const body = data;
  const res = await handleApiRequest(
    `/api/importProjectExcel/${id}?override=${override ? 1 : 0}`,
    "post",
    body
  );
  return res;
};

export const importTasks = async (data) => {
  const body = data;
  const res = await handleApiRequest(`/api/project-task-import`, "post", body);
  return res;
};

export const deleteProject = async (projectId) => {
  const res = await handleApiRequest(`/api/project/${projectId}`, "delete");
  return res;
};
export const getProjectMembers = async (projectId, page) => {
  const currentPage = page || 1;

  const res = await handleApiRequest(
    `/api/project/members/${projectId}?page=${currentPage}`,
    "get"
  );
  return res;
};

export const deleteProjectMember = async (memberId) => {
  const res = await handleApiRequest(
    `/api/project/member/${memberId}`,
    "delete"
  );
  return res;
};

export const deleteTask = async (taskId) => {
  const res = await handleApiRequest(`/api/project/task/${taskId}`, "delete");
  return res;
};

export const addProjectMember = async (memberData) => {
  const body = memberData;
  const res = await handleApiRequest(`/api/project/member`, "post", body);
  return res;
};

export const editProjectMember = async (memberData) => {
  const body = memberData;
  const res = await handleApiRequest(
    `/api/project/member/${memberData.member_id}`,
    "put",
    body
  );
  return res;
};

export const updateProjectStage = async (projectId, stage) => {
  const body = {
    stage: stage,
  };
  const res = await handleApiRequest(
    `/api/project-stage/${projectId}`,
    "put",
    body
  );
  return res;
};

export const updateProjectStatus = async (projectId, status) => {
  const body = {
    status: status,
  };
  const res = await handleApiRequest(
    `/api/project-status/${projectId}`,
    "put",
    body
  );

  return res;
};

// Fetch Milestone types

export const getMilestoneTypes = async () => {
  const res = await handleApiRequest(`/api/project/milestone/types`, "get");

  return res;
};

// Add milestone
export const addNewMilestone = async (data) => {
  const body = {
    ...data,
  };
  const res = await handleApiRequest(`/api/project/milestone`, "post", body);

  return res;
};

// Fetch all milestones
export const getAllProjectMilestones = async ({ projectId, page }) => {
  const res = await handleApiRequest(
    `/api/project/milestones/${projectId}?page${page || 1}`,
    "get"
  );
  return res;
};

// remove milestone
export const removeMilestone = async ({ milestoneId }) => {
  const res = await handleApiRequest(
    `/api/project/milestone/${milestoneId}`,
    "delete"
  );
  return res;
};

// edit milestone
export const updateMilestone = async (data) => {
  const body = {
    ...data,
  };
  const res = await handleApiRequest(
    `/api/project/milestone/${data.mile_id}`,
    "put",
    body
  );

  return res;
};

// Add new issue
export const addNewIssue = async (data) => {
  const body = data;
  const res = await handleApiRequest(`/api/project/issue`, "post", body);
  return res;
};

// Fetch all issues
export const getAllProjectIssues = async ({ projectId, page }) => {
  const res = await handleApiRequest(
    `/api/project/issues/${projectId}?page=${page || 1}`,
    "get"
  );
  return res;
};

export const getIssueFileURL = async (issueId) => {
  const res = await handleApiRequest(
    `/api/project/issues/doc/${issueId}`,
    "get"
  );
  return res;
};

// Remove issue
export const removeIssue = async ({ issueId }) => {
  const res = await handleApiRequest(`/api/project/issue/${issueId}`, "delete");
  return res;
};

// edit issue
export const updateIssue = async (data, issueId) => {
  const body = data;
  const res = await handleApiRequest(
    `/api/project/edit-issue/${issueId}`,
    "post",
    body
  );
  return res;
};

// fetch all user under admin
export const getUsersUnderAdmin = async (extraParams = 0, department = 0) => {
  const res = await handleApiRequest(
    `/api/people-dropdown?extra_params=${extraParams}&dept=${department}`,
    "get"
  );
  return res;
};

// fetch all Task type
export const getTaskType = async () => {
  const res = await handleApiRequest(`/api/project/task/type`, "get");
  return res;
};

// fetch all Task Status type
export const getTaskStatuses = async () => {
  const res = await handleApiRequest(`/api/project/task/status`, "get");
  return res;
};

// fetch all Task Priorities
export const getTaskPriorities = async () => {
  const res = await handleApiRequest(`/api/project/task/priority`, "get");
  return res;
};

// Project dropdown
export const getProjectDropdown = async () => {
  const res = await handleApiRequest(`/api/show-projects`, "get");
  return res;
};

// Project dropdown
export const getSavedTemplates = async (title) => {
  const res = await handleApiRequest(
    `/api/show-templates?title=${title || ""}`,
    "get"
  );
  return res;
};

// Milestones dropdown
export const getProjectMilestones = async ({ projectId }) => {
  const res = await handleApiRequest(
    `/api/project/milestone-dropdown/${projectId}`,
    "get"
  );
  return res;
};

// Add task
export const addTask = async (data) => {
  const body = data;
  const res = await handleApiRequest(`/api/project/task`, "post", body);
  return res;
};

// Edit task
export const editTask = async (data, taskId) => {
  const body = data;
  const res = await handleApiRequest(`/api/project/task-edit`, "post", body);
  return res;
};

// get Paginated tasks
export const getProjectTasks = async ({
  projectId,
  taskId,
  page,
  sortBy,
  shared,
  authToken,
}) => {
  const header = authToken ? { sharedKey: authToken } : {};
  const res = await handleApiRequest(
    !shared
      ? `/api/project/task/${projectId}?page=${page || 1}&sort_by=${
          sortBy || ""
        }&task_id=`
      : `/api/shared/project-tasks-list/${projectId}?page=${
          page || 1
        }&sort_by=${sortBy || ""}&task_id=${taskId || ""}`,
    "get",
    null,
    header
  );
  return res;
};

export const getAllProjectTasks = async (projectId) => {
  const res = await handleApiRequest(
    `/api/project/get-all-task/${projectId}`,
    "get"
  );
  return res;
};

export const orderProjectTasks = async (taskId, order) => {
  const body = {
    order: order,
  };
  const res = await handleApiRequest(
    `/api/project/update-task-order/${taskId}`,
    "put",
    body
  );
  return res;
};

// get all tasks
export const getDropdownTasks = async ({ projectId }) => {
  const res = await handleApiRequest(
    `/api/project/task-dropdown/${projectId}`,
    "get"
  );
  return res;
};

// get all tasks
export const getTaskTimeLog = async (taskId) => {
  const body = {
    task_id: taskId,
  };
  const res = await handleApiRequest(`/api/getTaskTimeLogs`, "post", body);
  return res;
};

// Add task time Log
export const addTimeLog = async ({ taskId, date, timeLogged }) => {
  const body = {
    task_id: taskId,
    day_logged: date,
    time_logged: timeLogged,
    task_time_id: "",
  };
  const res = await handleApiRequest(`/api/logTimeInTask`, "post", body);
  return res;
};

// Gantt chat view
export const getGanttChartData = async (projectId, shared, authToken) => {
  const header = authToken ? { sharedKey: authToken } : {};

  const URL = shared
    ? `/api/shared/project-gantt/${projectId}`
    : `/api/project/gantt/${projectId}`;
  const res = await handleApiRequest(`${URL}`, "get", null, header);
  return res;
};

// Board View
export const getBoardViewData = async (projectId) => {
  const res = await handleApiRequest(`/api/project/board/${projectId}`, "get");
  return res;
};

// Tree View
export const getTreeViewData = async ({ projectId }) => {
  const res = await handleApiRequest(
    `/api/project/tree-view-data/${projectId}`,
    "get"
  );
  return res;
};

// Update Milestone Order
export const updateMilestoneOrder = async ({ milestoneId, order }) => {
  const body = {
    order: order,
  };
  const res = await handleApiRequest(
    `/api/project/milestone-update-task/${milestoneId}`,
    "put",
    body
  );
  return res;
};

// Update Task Milestone
export const updateTaskMilestone = async ({ taskId, milestoneId }) => {
  const body = {
    milestoneId: milestoneId,
  };
  const res = await handleApiRequest(
    `/api/project/update-task/${taskId}`,
    "put",
    body
  );
  return res;
};

// Fetch all documents
export const getAllDocuments = async ({ projectId, page }) => {
  const res = await handleApiRequest(
    `/api/project/document/${projectId}?page=${page}`,
    "get"
  );
  return res;
};

// Download or get s3 doc
export const downloadDocument = async ({ documentId }) => {
  const res = await handleApiRequest(
    `/api/project/upload-doc/${documentId}`,
    "get"
  );
  return res;
};

// Upload to s3 doc
export const addDocumentToAWS = async (data) => {
  const body = data;
  const res = await handleApiRequest(`/api/project/upload-doc`, "post", body);
  return res;
};

// Upload document link
export const uploadDocumentLink = async ({
  projectId,
  documentTitle,
  uploadedBy,
  documentLink,
  documentExtension,
}) => {
  const body = {
    proj_id: projectId,
    doc_title: documentTitle,
    uploaded_by: uploadedBy,
    doc_link: documentLink,
    doc_extension: documentExtension,
  };
  const res = await handleApiRequest(`/api/project/link-doc`, "post", body);
  return res;
};

// Delete document
export const deleteDocument = async ({ documentId }) => {
  const res = await handleApiRequest(
    `/api/project/document/${documentId}`,
    "delete"
  );
  return res;
};

export const updateProjectDates = async (projectId, startDate, endDate) => {
  const body = {
    startDate: startDate,
    endDate: endDate,
  };
  const res = await handleApiRequest(
    `/api/project-date-range-update/${projectId}`,
    "post",
    body
  );
  return res;
};

// Add project budget
export const addProjectBudget = async ({
  projectId,
  data,
  isEdited,
  isCompleted,
  budgetTotal,
  actualTotal,
}) => {
  const body = {
    project_id: projectId,
    isEdited: isEdited,
    isCompleted: isCompleted,
    budgetTotal: budgetTotal,
    actualTotal: actualTotal,
    data: data,
  };
  const res = await handleApiRequest(`/api/addingBudget`, "post", body);
  return res;
};

// Edit project budget
export const updateProjectBudget = async ({
  projectId,
  data,
  isEdited,
  isCompleted,
  budgetTotal,
  actualTotal,
}) => {
  const body = {
    project_id: projectId,
    isEdited: isEdited,
    isCompleted: isCompleted,
    budgetTotal: budgetTotal,
    actualTotal: actualTotal,
    data: data,
  };
  const res = await handleApiRequest(`/api/updateBudget`, "post", body);
  return res;
};

export const duplicateProject = (projectId) => {
  const body = {
    project_id: projectId,
  };

  const res = handleApiRequest(
    `/api/duplicate-project/${projectId}`,
    "post",
    body
  );
  return res;
};

export const generateProjectFromTemplate = (templateId, projectStartDate) => {
  const body = {
    project_id: templateId,
    input_start_date: projectStartDate,
  };

  const res = handleApiRequest(
    `/api/template-to-project/${templateId}`,
    "post",
    body
  );
  return res;
};

export const saveAsTemplate = (projectId, title, description) => {
  const body = {
    title: title,
    description: description,
  };

  const res = handleApiRequest(
    `/api/project-template/${projectId}`,
    "post",
    body
  );
  return res;
};

export const editTemplate = (templateId, title, description) => {
  const body = {
    title: title,
    description: description,
  };

  const res = handleApiRequest(`/api/template-edit/${templateId}`, "put", body);
  return res;
};

export const deleteTemplate = async (templateID) => {
  const res = await handleApiRequest(
    `/api/template-disable/${templateID}`,
    "delete"
  );
  return res;
};

export const addComment = async (projectId, comment) => {
  const body = {
    project_id: projectId,
    comment: comment,
  };

  const res = await handleApiRequest(`/api/project-comments`, "post", body);
  return res;
};

export const getAllComments = async (projectId) => {
  const res = await handleApiRequest(
    `/api/project-comments/${projectId}`,
    "get"
  );
  return res;
};

export const setDefaultProjectTasks = async () => {
  const res = await handleApiRequest(
    `/api/common/check-daily-for-task-reminder`,
    "post",
    {}
  );
  return res;
};
